import { Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { RiskRatingEnum } from "@/apis/services/HazardService";

interface Props {
  open: boolean;
  onClose: () => void;
  anchor?: HTMLElement;
  onChange: (rating: RiskRatingEnum) => void;
}

export const ChipRatingBarOptions = (props: Props) => {
  const { open, onClose, anchor, onChange } = props;
  const { t } = useTranslation();

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchor}
      disableScrollLock
      anchorOrigin={{
        horizontal: "right",
        vertical: "center",
      }}
      transformOrigin={{
        horizontal: "left",
        vertical: "center",
      }}
    >
      {Object.values(RiskRatingEnum).map((rating, i) => (
        <MenuItem
          key={rating + i}
          value={rating}
          onClick={() => onChange(rating)}
          data-test={`chip-rating-bar-options-${rating}`}
        >
          <Typography variant="h5">{t(rating)}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};
